export const SnackBar = () => import('../../components/SnackBar.vue' /* webpackChunkName: "components/snack-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseFormAutocompleteElement = () => import('../../components/baseForm/AutocompleteElement.vue' /* webpackChunkName: "components/base-form-autocomplete-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormBooleanElement = () => import('../../components/baseForm/BooleanElement.vue' /* webpackChunkName: "components/base-form-boolean-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormDateElement = () => import('../../components/baseForm/DateElement.vue' /* webpackChunkName: "components/base-form-date-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormFarmunitSearch = () => import('../../components/baseForm/FarmunitSearch.vue' /* webpackChunkName: "components/base-form-farmunit-search" */).then(c => wrapFunctional(c.default || c))
export const BaseFormHeadingElement = () => import('../../components/baseForm/HeadingElement.vue' /* webpackChunkName: "components/base-form-heading-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormHiddenElement = () => import('../../components/baseForm/HiddenElement.vue' /* webpackChunkName: "components/base-form-hidden-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormNestedCard = () => import('../../components/baseForm/NestedCard.vue' /* webpackChunkName: "components/base-form-nested-card" */).then(c => wrapFunctional(c.default || c))
export const BaseFormNestedForm = () => import('../../components/baseForm/NestedForm.vue' /* webpackChunkName: "components/base-form-nested-form" */).then(c => wrapFunctional(c.default || c))
export const BaseFormOwnerSearch = () => import('../../components/baseForm/OwnerSearch.vue' /* webpackChunkName: "components/base-form-owner-search" */).then(c => wrapFunctional(c.default || c))
export const BaseFormPasswordElement = () => import('../../components/baseForm/PasswordElement.vue' /* webpackChunkName: "components/base-form-password-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormPhoneElement = () => import('../../components/baseForm/PhoneElement.vue' /* webpackChunkName: "components/base-form-phone-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormProducerSearch = () => import('../../components/baseForm/ProducerSearch.vue' /* webpackChunkName: "components/base-form-producer-search" */).then(c => wrapFunctional(c.default || c))
export const BaseFormProducerTurnoutList = () => import('../../components/baseForm/ProducerTurnoutList.vue' /* webpackChunkName: "components/base-form-producer-turnout-list" */).then(c => wrapFunctional(c.default || c))
export const BaseFormRadioElement = () => import('../../components/baseForm/RadioElement.vue' /* webpackChunkName: "components/base-form-radio-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormRadioFormBuilder = () => import('../../components/baseForm/RadioFormBuilder.vue' /* webpackChunkName: "components/base-form-radio-form-builder" */).then(c => wrapFunctional(c.default || c))
export const BaseFormReadOnlyElement = () => import('../../components/baseForm/ReadOnlyElement.vue' /* webpackChunkName: "components/base-form-read-only-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSelectElement = () => import('../../components/baseForm/SelectElement.vue' /* webpackChunkName: "components/base-form-select-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSelectLocalConfig = () => import('../../components/baseForm/SelectLocalConfig.vue' /* webpackChunkName: "components/base-form-select-local-config" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSelectStoreConfig = () => import('../../components/baseForm/SelectStoreConfig.vue' /* webpackChunkName: "components/base-form-select-store-config" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSubSelectElement = () => import('../../components/baseForm/SubSelectElement.vue' /* webpackChunkName: "components/base-form-sub-select-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSwitchElement = () => import('../../components/baseForm/SwitchElement.vue' /* webpackChunkName: "components/base-form-switch-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormTextBlockElement = () => import('../../components/baseForm/TextBlockElement.vue' /* webpackChunkName: "components/base-form-text-block-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormTextElement = () => import('../../components/baseForm/TextElement.vue' /* webpackChunkName: "components/base-form-text-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormTextareaElement = () => import('../../components/baseForm/TextareaElement.vue' /* webpackChunkName: "components/base-form-textarea-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormTimeElement = () => import('../../components/baseForm/TimeElement.vue' /* webpackChunkName: "components/base-form-time-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormTimePicker = () => import('../../components/baseForm/TimePicker.vue' /* webpackChunkName: "components/base-form-time-picker" */).then(c => wrapFunctional(c.default || c))
export const BaseFormTimePickerAmPm = () => import('../../components/baseForm/TimePickerAmPm.vue' /* webpackChunkName: "components/base-form-time-picker-am-pm" */).then(c => wrapFunctional(c.default || c))
export const BaseFormVerboseParent = () => import('../../components/baseForm/VerboseParent.vue' /* webpackChunkName: "components/base-form-verbose-parent" */).then(c => wrapFunctional(c.default || c))
export const BaseFormVerboseSelectElement = () => import('../../components/baseForm/VerboseSelectElement.vue' /* webpackChunkName: "components/base-form-verbose-select-element" */).then(c => wrapFunctional(c.default || c))
export const BaseFormVerboseTextElement = () => import('../../components/baseForm/VerboseTextElement.vue' /* webpackChunkName: "components/base-form-verbose-text-element" */).then(c => wrapFunctional(c.default || c))
export const AppEditTable = () => import('../../components/app/EditTable.vue' /* webpackChunkName: "components/app-edit-table" */).then(c => wrapFunctional(c.default || c))
export const AppForm = () => import('../../components/app/Form.vue' /* webpackChunkName: "components/app-form" */).then(c => wrapFunctional(c.default || c))
export const AppOverlay = () => import('../../components/app/Overlay.vue' /* webpackChunkName: "components/app-overlay" */).then(c => wrapFunctional(c.default || c))
export const AppTable = () => import('../../components/app/Table.vue' /* webpackChunkName: "components/app-table" */).then(c => wrapFunctional(c.default || c))
export const ColumnAction = () => import('../../components/column/Action.vue' /* webpackChunkName: "components/column-action" */).then(c => wrapFunctional(c.default || c))
export const ColumnActionString = () => import('../../components/column/ActionString.vue' /* webpackChunkName: "components/column-action-string" */).then(c => wrapFunctional(c.default || c))
export const ColumnActionText = () => import('../../components/column/ActionText.vue' /* webpackChunkName: "components/column-action-text" */).then(c => wrapFunctional(c.default || c))
export const ColumnBoolicon = () => import('../../components/column/Boolicon.vue' /* webpackChunkName: "components/column-boolicon" */).then(c => wrapFunctional(c.default || c))
export const ColumnCheckbox = () => import('../../components/column/Checkbox.vue' /* webpackChunkName: "components/column-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ColumnDate = () => import('../../components/column/Date.vue' /* webpackChunkName: "components/column-date" */).then(c => wrapFunctional(c.default || c))
export const ColumnDateTime = () => import('../../components/column/DateTime.vue' /* webpackChunkName: "components/column-date-time" */).then(c => wrapFunctional(c.default || c))
export const ColumnDelete = () => import('../../components/column/Delete.vue' /* webpackChunkName: "components/column-delete" */).then(c => wrapFunctional(c.default || c))
export const ColumnDetachContact = () => import('../../components/column/DetachContact.vue' /* webpackChunkName: "components/column-detach-contact" */).then(c => wrapFunctional(c.default || c))
export const ColumnDiversionPointActions = () => import('../../components/column/DiversionPointActions.vue' /* webpackChunkName: "components/column-diversion-point-actions" */).then(c => wrapFunctional(c.default || c))
export const ColumnDiversionPointStatus = () => import('../../components/column/DiversionPointStatus.vue' /* webpackChunkName: "components/column-diversion-point-status" */).then(c => wrapFunctional(c.default || c))
export const ColumnDropdown = () => import('../../components/column/Dropdown.vue' /* webpackChunkName: "components/column-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ColumnEditOrder = () => import('../../components/column/EditOrder.vue' /* webpackChunkName: "components/column-edit-order" */).then(c => wrapFunctional(c.default || c))
export const ColumnFulfillOrder = () => import('../../components/column/FulfillOrder.vue' /* webpackChunkName: "components/column-fulfill-order" */).then(c => wrapFunctional(c.default || c))
export const ColumnHtml = () => import('../../components/column/Html.vue' /* webpackChunkName: "components/column-html" */).then(c => wrapFunctional(c.default || c))
export const ColumnList = () => import('../../components/column/List.vue' /* webpackChunkName: "components/column-list" */).then(c => wrapFunctional(c.default || c))
export const ColumnListPDF = () => import('../../components/column/ListPDF.vue' /* webpackChunkName: "components/column-list-p-d-f" */).then(c => wrapFunctional(c.default || c))
export const ColumnMoney = () => import('../../components/column/Money.vue' /* webpackChunkName: "components/column-money" */).then(c => wrapFunctional(c.default || c))
export const ColumnNumber = () => import('../../components/column/Number.vue' /* webpackChunkName: "components/column-number" */).then(c => wrapFunctional(c.default || c))
export const ColumnOrderActions = () => import('../../components/column/OrderActions.vue' /* webpackChunkName: "components/column-order-actions" */).then(c => wrapFunctional(c.default || c))
export const ColumnPhone = () => import('../../components/column/Phone.vue' /* webpackChunkName: "components/column-phone" */).then(c => wrapFunctional(c.default || c))
export const ColumnReadOnly = () => import('../../components/column/ReadOnly.vue' /* webpackChunkName: "components/column-read-only" */).then(c => wrapFunctional(c.default || c))
export const ColumnRelatedItemText = () => import('../../components/column/RelatedItemText.vue' /* webpackChunkName: "components/column-related-item-text" */).then(c => wrapFunctional(c.default || c))
export const ColumnRelationshipList = () => import('../../components/column/RelationshipList.vue' /* webpackChunkName: "components/column-relationship-list" */).then(c => wrapFunctional(c.default || c))
export const ColumnTableText = () => import('../../components/column/TableText.vue' /* webpackChunkName: "components/column-table-text" */).then(c => wrapFunctional(c.default || c))
export const ColumnTimeAPM = () => import('../../components/column/TimeAPM.vue' /* webpackChunkName: "components/column-time-a-p-m" */).then(c => wrapFunctional(c.default || c))
export const ColumnTurnout = () => import('../../components/column/Turnout.vue' /* webpackChunkName: "components/column-turnout" */).then(c => wrapFunctional(c.default || c))
export const ColumnUppercaseChip = () => import('../../components/column/UppercaseChip.vue' /* webpackChunkName: "components/column-uppercase-chip" */).then(c => wrapFunctional(c.default || c))
export const ColumnUser = () => import('../../components/column/User.vue' /* webpackChunkName: "components/column-user" */).then(c => wrapFunctional(c.default || c))
export const ColumnUsageAdjustment = () => import('../../components/column/usageAdjustment.vue' /* webpackChunkName: "components/column-usage-adjustment" */).then(c => wrapFunctional(c.default || c))
export const ColumnUsageOff = () => import('../../components/column/usageOff.vue' /* webpackChunkName: "components/column-usage-off" */).then(c => wrapFunctional(c.default || c))
export const CommonBack = () => import('../../components/common/Back.vue' /* webpackChunkName: "components/common-back" */).then(c => wrapFunctional(c.default || c))
export const CommonDeleteButton = () => import('../../components/common/DeleteButton.vue' /* webpackChunkName: "components/common-delete-button" */).then(c => wrapFunctional(c.default || c))
export const CommonLoading = () => import('../../components/common/Loading.vue' /* webpackChunkName: "components/common-loading" */).then(c => wrapFunctional(c.default || c))
export const BlocksIndexHeader = () => import('../../components/blocks/IndexHeader.vue' /* webpackChunkName: "components/blocks-index-header" */).then(c => wrapFunctional(c.default || c))
export const BlocksListActivator = () => import('../../components/blocks/ListActivator.vue' /* webpackChunkName: "components/blocks-list-activator" */).then(c => wrapFunctional(c.default || c))
export const BlocksOrderInstructions = () => import('../../components/blocks/OrderInstructions.vue' /* webpackChunkName: "components/blocks-order-instructions" */).then(c => wrapFunctional(c.default || c))
export const BlocksSubheader = () => import('../../components/blocks/Subheader.vue' /* webpackChunkName: "components/blocks-subheader" */).then(c => wrapFunctional(c.default || c))
export const BlocksDivider = () => import('../../components/blocks/divider.vue' /* webpackChunkName: "components/blocks-divider" */).then(c => wrapFunctional(c.default || c))
export const DialogsAttachDialog = () => import('../../components/dialogs/AttachDialog.vue' /* webpackChunkName: "components/dialogs-attach-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsBasicDialog = () => import('../../components/dialogs/BasicDialog.vue' /* webpackChunkName: "components/dialogs-basic-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsDetachDialog = () => import('../../components/dialogs/DetachDialog.vue' /* webpackChunkName: "components/dialogs-detach-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsEditOrderDialog = () => import('../../components/dialogs/EditOrderDialog.vue' /* webpackChunkName: "components/dialogs-edit-order-dialog" */).then(c => wrapFunctional(c.default || c))
export const CardsBasicCard = () => import('../../components/cards/BasicCard.vue' /* webpackChunkName: "components/cards-basic-card" */).then(c => wrapFunctional(c.default || c))
export const CardsContactShowDisplay = () => import('../../components/cards/ContactShowDisplay.vue' /* webpackChunkName: "components/cards-contact-show-display" */).then(c => wrapFunctional(c.default || c))
export const CardsCreate = () => import('../../components/cards/Create.vue' /* webpackChunkName: "components/cards-create" */).then(c => wrapFunctional(c.default || c))
export const CardsCreateOrder = () => import('../../components/cards/CreateOrder.vue' /* webpackChunkName: "components/cards-create-order" */).then(c => wrapFunctional(c.default || c))
export const CardsCreateUsage = () => import('../../components/cards/CreateUsage.vue' /* webpackChunkName: "components/cards-create-usage" */).then(c => wrapFunctional(c.default || c))
export const CardsDailyOrdersCard = () => import('../../components/cards/DailyOrdersCard.vue' /* webpackChunkName: "components/cards-daily-orders-card" */).then(c => wrapFunctional(c.default || c))
export const CardsDefaultDelete = () => import('../../components/cards/DefaultDelete.vue' /* webpackChunkName: "components/cards-default-delete" */).then(c => wrapFunctional(c.default || c))
export const CardsDeleteResource = () => import('../../components/cards/DeleteResource.vue' /* webpackChunkName: "components/cards-delete-resource" */).then(c => wrapFunctional(c.default || c))
export const CardsDiversionPointShowDisplay = () => import('../../components/cards/DiversionPointShowDisplay.vue' /* webpackChunkName: "components/cards-diversion-point-show-display" */).then(c => wrapFunctional(c.default || c))
export const CardsFarmunitShowDisplay = () => import('../../components/cards/FarmunitShowDisplay.vue' /* webpackChunkName: "components/cards-farmunit-show-display" */).then(c => wrapFunctional(c.default || c))
export const CardsFlagManagement = () => import('../../components/cards/FlagManagement.vue' /* webpackChunkName: "components/cards-flag-management" */).then(c => wrapFunctional(c.default || c))
export const CardsFlagReportCard = () => import('../../components/cards/FlagReportCard.vue' /* webpackChunkName: "components/cards-flag-report-card" */).then(c => wrapFunctional(c.default || c))
export const CardsIndexDisplay = () => import('../../components/cards/IndexDisplay.vue' /* webpackChunkName: "components/cards-index-display" */).then(c => wrapFunctional(c.default || c))
export const CardsOfflineIndexDisplay = () => import('../../components/cards/OfflineIndexDisplay.vue' /* webpackChunkName: "components/cards-offline-index-display" */).then(c => wrapFunctional(c.default || c))
export const CardsOwnershipPercent = () => import('../../components/cards/OwnershipPercent.vue' /* webpackChunkName: "components/cards-ownership-percent" */).then(c => wrapFunctional(c.default || c))
export const CardsReportsCard = () => import('../../components/cards/ReportsCard.vue' /* webpackChunkName: "components/cards-reports-card" */).then(c => wrapFunctional(c.default || c))
export const CardsSelectCreate = () => import('../../components/cards/SelectCreate.vue' /* webpackChunkName: "components/cards-select-create" */).then(c => wrapFunctional(c.default || c))
export const CardsSimpleDelete = () => import('../../components/cards/SimpleDelete.vue' /* webpackChunkName: "components/cards-simple-delete" */).then(c => wrapFunctional(c.default || c))
export const CardsSubCreate = () => import('../../components/cards/SubCreate.vue' /* webpackChunkName: "components/cards-sub-create" */).then(c => wrapFunctional(c.default || c))
export const CardsShowDisplay = () => import('../../components/cards/showDisplay.vue' /* webpackChunkName: "components/cards-show-display" */).then(c => wrapFunctional(c.default || c))
export const ExportsExcelXlsx = () => import('../../components/exports/ExcelXlsx.vue' /* webpackChunkName: "components/exports-excel-xlsx" */).then(c => wrapFunctional(c.default || c))
export const ExportsExport = () => import('../../components/exports/Export.vue' /* webpackChunkName: "components/exports-export" */).then(c => wrapFunctional(c.default || c))
export const ExportsPdf = () => import('../../components/exports/Pdf.vue' /* webpackChunkName: "components/exports-pdf" */).then(c => wrapFunctional(c.default || c))
export const ExportsRelations = () => import('../../components/exports/Relations.vue' /* webpackChunkName: "components/exports-relations" */).then(c => wrapFunctional(c.default || c))
export const ExportsServerPdf = () => import('../../components/exports/ServerPdf.vue' /* webpackChunkName: "components/exports-server-pdf" */).then(c => wrapFunctional(c.default || c))
export const ExportsServerXlsx = () => import('../../components/exports/ServerXlsx.vue' /* webpackChunkName: "components/exports-server-xlsx" */).then(c => wrapFunctional(c.default || c))
export const ExportsSingleExport = () => import('../../components/exports/SingleExport.vue' /* webpackChunkName: "components/exports-single-export" */).then(c => wrapFunctional(c.default || c))
export const ExportsSinglePrint = () => import('../../components/exports/SinglePrint.vue' /* webpackChunkName: "components/exports-single-print" */).then(c => wrapFunctional(c.default || c))
export const ExportsXlsx = () => import('../../components/exports/Xlsx.vue' /* webpackChunkName: "components/exports-xlsx" */).then(c => wrapFunctional(c.default || c))
export const FiltersCustomMenu = () => import('../../components/filters/CustomMenu.vue' /* webpackChunkName: "components/filters-custom-menu" */).then(c => wrapFunctional(c.default || c))
export const FiltersDivisionFilter = () => import('../../components/filters/DivisionFilter.vue' /* webpackChunkName: "components/filters-division-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersHRFilterList = () => import('../../components/filters/HRFilterList.vue' /* webpackChunkName: "components/filters-h-r-filter-list" */).then(c => wrapFunctional(c.default || c))
export const FiltersSelectionButtons = () => import('../../components/filters/SelectionButtons.vue' /* webpackChunkName: "components/filters-selection-buttons" */).then(c => wrapFunctional(c.default || c))
export const FiltersTextFilter = () => import('../../components/filters/TextFilter.vue' /* webpackChunkName: "components/filters-text-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersMenu = () => import('../../components/filters/menu.vue' /* webpackChunkName: "components/filters-menu" */).then(c => wrapFunctional(c.default || c))
export const FormDpOrders = () => import('../../components/form/DpOrders.vue' /* webpackChunkName: "components/form-dp-orders" */).then(c => wrapFunctional(c.default || c))
export const FormEdgeConfig = () => import('../../components/form/EdgeConfig.vue' /* webpackChunkName: "components/form-edge-config" */).then(c => wrapFunctional(c.default || c))
export const FormElement = () => import('../../components/form/FormElement.vue' /* webpackChunkName: "components/form-element" */).then(c => wrapFunctional(c.default || c))
export const FormFieldMap = () => import('../../components/form/FormFieldMap.js' /* webpackChunkName: "components/form-field-map" */).then(c => wrapFunctional(c.default || c))
export const FormFieldRules = () => import('../../components/form/FormFieldRules.js' /* webpackChunkName: "components/form-field-rules" */).then(c => wrapFunctional(c.default || c))
export const FormHorizontalBar = () => import('../../components/form/HorizontalBar.vue' /* webpackChunkName: "components/form-horizontal-bar" */).then(c => wrapFunctional(c.default || c))
export const FormInlineDateEdit = () => import('../../components/form/InlineDateEdit.vue' /* webpackChunkName: "components/form-inline-date-edit" */).then(c => wrapFunctional(c.default || c))
export const FormInlineModelAutocomplete = () => import('../../components/form/InlineModelAutocomplete.vue' /* webpackChunkName: "components/form-inline-model-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormInlineTimeSelectInterval = () => import('../../components/form/InlineTimeSelectInterval.vue' /* webpackChunkName: "components/form-inline-time-select-interval" */).then(c => wrapFunctional(c.default || c))
export const FormModelAutocomplete = () => import('../../components/form/ModelAutocomplete.vue' /* webpackChunkName: "components/form-model-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormStatesSelect = () => import('../../components/form/StatesSelect.vue' /* webpackChunkName: "components/form-states-select" */).then(c => wrapFunctional(c.default || c))
export const FormSubHeading = () => import('../../components/form/SubHeading.vue' /* webpackChunkName: "components/form-sub-heading" */).then(c => wrapFunctional(c.default || c))
export const FormSwitchEdit = () => import('../../components/form/SwitchEdit.vue' /* webpackChunkName: "components/form-switch-edit" */).then(c => wrapFunctional(c.default || c))
export const FormTextEdit = () => import('../../components/form/TextEdit.vue' /* webpackChunkName: "components/form-text-edit" */).then(c => wrapFunctional(c.default || c))
export const FormTextMoney = () => import('../../components/form/TextMoney.vue' /* webpackChunkName: "components/form-text-money" */).then(c => wrapFunctional(c.default || c))
export const FormTextMoneyRaw = () => import('../../components/form/TextMoneyRaw.vue' /* webpackChunkName: "components/form-text-money-raw" */).then(c => wrapFunctional(c.default || c))
export const FormTextPhone = () => import('../../components/form/TextPhone.vue' /* webpackChunkName: "components/form-text-phone" */).then(c => wrapFunctional(c.default || c))
export const FormTextSelect = () => import('../../components/form/TextSelect.vue' /* webpackChunkName: "components/form-text-select" */).then(c => wrapFunctional(c.default || c))
export const FormTextareaEdit = () => import('../../components/form/TextareaEdit.vue' /* webpackChunkName: "components/form-textarea-edit" */).then(c => wrapFunctional(c.default || c))
export const FormTimeIntervalPicker = () => import('../../components/form/TimeIntervalPicker.vue' /* webpackChunkName: "components/form-time-interval-picker" */).then(c => wrapFunctional(c.default || c))
export const LayoutDoubleMenu = () => import('../../components/layout/DoubleMenu.vue' /* webpackChunkName: "components/layout-double-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutFullWidth = () => import('../../components/layout/FullWidth.vue' /* webpackChunkName: "components/layout-full-width" */).then(c => wrapFunctional(c.default || c))
export const LayoutFullWidthParent = () => import('../../components/layout/FullWidthParent.vue' /* webpackChunkName: "components/layout-full-width-parent" */).then(c => wrapFunctional(c.default || c))
export const LayoutOneFourth = () => import('../../components/layout/OneFourth.vue' /* webpackChunkName: "components/layout-one-fourth" */).then(c => wrapFunctional(c.default || c))
export const LayoutOneThirds = () => import('../../components/layout/OneThirds.vue' /* webpackChunkName: "components/layout-one-thirds" */).then(c => wrapFunctional(c.default || c))
export const LayoutPaneView = () => import('../../components/layout/PaneView.vue' /* webpackChunkName: "components/layout-pane-view" */).then(c => wrapFunctional(c.default || c))
export const LayoutStructureTable = () => import('../../components/layout/StructureTable.vue' /* webpackChunkName: "components/layout-structure-table" */).then(c => wrapFunctional(c.default || c))
export const LayoutThreeFourths = () => import('../../components/layout/ThreeFourths.vue' /* webpackChunkName: "components/layout-three-fourths" */).then(c => wrapFunctional(c.default || c))
export const LayoutTwoThirds = () => import('../../components/layout/TwoThirds.vue' /* webpackChunkName: "components/layout-two-thirds" */).then(c => wrapFunctional(c.default || c))
export const ListsBasicList = () => import('../../components/lists/BasicList.vue' /* webpackChunkName: "components/lists-basic-list" */).then(c => wrapFunctional(c.default || c))
export const ListsDateFilterList = () => import('../../components/lists/DateFilterList.vue' /* webpackChunkName: "components/lists-date-filter-list" */).then(c => wrapFunctional(c.default || c))
export const ListsDiversionPointsList = () => import('../../components/lists/DiversionPointsList.vue' /* webpackChunkName: "components/lists-diversion-points-list" */).then(c => wrapFunctional(c.default || c))
export const ListsFilterableList = () => import('../../components/lists/FilterableList.vue' /* webpackChunkName: "components/lists-filterable-list" */).then(c => wrapFunctional(c.default || c))
export const ListsFlagsList = () => import('../../components/lists/FlagsList.vue' /* webpackChunkName: "components/lists-flags-list" */).then(c => wrapFunctional(c.default || c))
export const ListsHRFilterList = () => import('../../components/lists/HRFilterList.vue' /* webpackChunkName: "components/lists-h-r-filter-list" */).then(c => wrapFunctional(c.default || c))
export const ListsNotesList = () => import('../../components/lists/NotesList.vue' /* webpackChunkName: "components/lists-notes-list" */).then(c => wrapFunctional(c.default || c))
export const ReportsBaseReport = () => import('../../components/reports/BaseReport.vue' /* webpackChunkName: "components/reports-base-report" */).then(c => wrapFunctional(c.default || c))
export const ReportsCounty = () => import('../../components/reports/County.vue' /* webpackChunkName: "components/reports-county" */).then(c => wrapFunctional(c.default || c))
export const ReportsDRLog = () => import('../../components/reports/DRLog.vue' /* webpackChunkName: "components/reports-d-r-log" */).then(c => wrapFunctional(c.default || c))
export const ReportsDateDivision = () => import('../../components/reports/DateDivision.vue' /* webpackChunkName: "components/reports-date-division" */).then(c => wrapFunctional(c.default || c))
export const ReportsDateOnly = () => import('../../components/reports/DateOnly.vue' /* webpackChunkName: "components/reports-date-only" */).then(c => wrapFunctional(c.default || c))
export const ReportsDistrictVoters = () => import('../../components/reports/DistrictVoters.vue' /* webpackChunkName: "components/reports-district-voters" */).then(c => wrapFunctional(c.default || c))
export const ReportsDivision = () => import('../../components/reports/Division.vue' /* webpackChunkName: "components/reports-division" */).then(c => wrapFunctional(c.default || c))
export const ReportsOwner = () => import('../../components/reports/Owner.vue' /* webpackChunkName: "components/reports-owner" */).then(c => wrapFunctional(c.default || c))
export const ReportsPercentage = () => import('../../components/reports/Percentage.vue' /* webpackChunkName: "components/reports-percentage" */).then(c => wrapFunctional(c.default || c))
export const ReportsProducer = () => import('../../components/reports/Producer.vue' /* webpackChunkName: "components/reports-producer" */).then(c => wrapFunctional(c.default || c))
export const SearchHorizontalSearchBar = () => import('../../components/search/HorizontalSearchBar.vue' /* webpackChunkName: "components/search-horizontal-search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchCheckbox = () => import('../../components/search/SearchCheckbox.vue' /* webpackChunkName: "components/search-checkbox" */).then(c => wrapFunctional(c.default || c))
export const SearchDatewy = () => import('../../components/search/SearchDatewy.vue' /* webpackChunkName: "components/search-datewy" */).then(c => wrapFunctional(c.default || c))
export const SearchProducersByDistrict = () => import('../../components/search/SearchProducersByDistrict.vue' /* webpackChunkName: "components/search-producers-by-district" */).then(c => wrapFunctional(c.default || c))
export const SearchRelated = () => import('../../components/search/SearchRelated.vue' /* webpackChunkName: "components/search-related" */).then(c => wrapFunctional(c.default || c))
export const SearchSelect = () => import('../../components/search/SearchSelect.vue' /* webpackChunkName: "components/search-select" */).then(c => wrapFunctional(c.default || c))
export const SearchText = () => import('../../components/search/SearchText.vue' /* webpackChunkName: "components/search-text" */).then(c => wrapFunctional(c.default || c))
export const SectionsBreadcrumb = () => import('../../components/sections/Breadcrumb.vue' /* webpackChunkName: "components/sections-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const SectionsFullwidthHeader = () => import('../../components/sections/FullwidthHeader.vue' /* webpackChunkName: "components/sections-fullwidth-header" */).then(c => wrapFunctional(c.default || c))
export const SectionsMain = () => import('../../components/sections/Main.vue' /* webpackChunkName: "components/sections-main" */).then(c => wrapFunctional(c.default || c))
export const SectionsPaneList = () => import('../../components/sections/PaneList.vue' /* webpackChunkName: "components/sections-pane-list" */).then(c => wrapFunctional(c.default || c))
export const SectionsPaneMainView = () => import('../../components/sections/PaneMainView.vue' /* webpackChunkName: "components/sections-pane-main-view" */).then(c => wrapFunctional(c.default || c))
export const SectionsQuickFilters = () => import('../../components/sections/QuickFilters.vue' /* webpackChunkName: "components/sections-quick-filters" */).then(c => wrapFunctional(c.default || c))
export const SectionsRight = () => import('../../components/sections/Right.vue' /* webpackChunkName: "components/sections-right" */).then(c => wrapFunctional(c.default || c))
export const SectionsToolbar = () => import('../../components/sections/Toolbar.vue' /* webpackChunkName: "components/sections-toolbar" */).then(c => wrapFunctional(c.default || c))
export const WidgetsDivisionFlows = () => import('../../components/widgets/DivisionFlows.vue' /* webpackChunkName: "components/widgets-division-flows" */).then(c => wrapFunctional(c.default || c))
export const WidgetsTopProducers = () => import('../../components/widgets/TopProducers.vue' /* webpackChunkName: "components/widgets-top-producers" */).then(c => wrapFunctional(c.default || c))
export const WidgetsCfsChart = () => import('../../components/widgets/cfsChart.vue' /* webpackChunkName: "components/widgets-cfs-chart" */).then(c => wrapFunctional(c.default || c))
export const WidgetsOrdersFiltered = () => import('../../components/widgets/ordersFiltered.vue' /* webpackChunkName: "components/widgets-orders-filtered" */).then(c => wrapFunctional(c.default || c))
export const WidgetsRemindersTicker = () => import('../../components/widgets/remindersTicker.vue' /* webpackChunkName: "components/widgets-reminders-ticker" */).then(c => wrapFunctional(c.default || c))
export const WidgetsTodaysOrders = () => import('../../components/widgets/todaysOrders.vue' /* webpackChunkName: "components/widgets-todays-orders" */).then(c => wrapFunctional(c.default || c))
export const ColumnOfflineCancelRequest = () => import('../../components/column/offline/CancelRequest.vue' /* webpackChunkName: "components/column-offline-cancel-request" */).then(c => wrapFunctional(c.default || c))
export const ColumnOfflineCheckRequest = () => import('../../components/column/offline/CheckRequest.vue' /* webpackChunkName: "components/column-offline-check-request" */).then(c => wrapFunctional(c.default || c))
export const ColumnOfflineEditRequest = () => import('../../components/column/offline/EditRequest.vue' /* webpackChunkName: "components/column-offline-edit-request" */).then(c => wrapFunctional(c.default || c))
export const ColumnOfflineRequestModel = () => import('../../components/column/offline/RequestModel.vue' /* webpackChunkName: "components/column-offline-request-model" */).then(c => wrapFunctional(c.default || c))
export const ColumnOfflineRequestPayload = () => import('../../components/column/offline/RequestPayload.vue' /* webpackChunkName: "components/column-offline-request-payload" */).then(c => wrapFunctional(c.default || c))
export const ColumnOfflineRequestStatus = () => import('../../components/column/offline/RequestStatus.vue' /* webpackChunkName: "components/column-offline-request-status" */).then(c => wrapFunctional(c.default || c))
export const ColumnOfflineSendRequest = () => import('../../components/column/offline/SendRequest.vue' /* webpackChunkName: "components/column-offline-send-request" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
